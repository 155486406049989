<template>
  <mf-button
    outlined
    color="primary"
    :label="label"
    icon="autorenew"
    iconColor="primary"
    iconPosition="left"
    :disabled="disabled"
    :loading="loading"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'ReprocessOffersButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>
